import { ContentContext } from '@components/layouts'
import React, { useContext, useEffect } from 'react'
import { FaGlobeAfrica } from '@react-icons/all-files/fa/FaGlobeAfrica'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast, { Toaster } from 'react-hot-toast'
import { Link } from 'gatsby'

interface Props {
  postImage: string
  category: string
  title: string
  description: string
  at: string
  author: string
  postID: string
  url?: string
}

const BlogLatestPostCard: React.FC<Props> = ({
  postImage,
  category,
  title,
  description,
  at,
  author,
  postID,
  url
}) => {
  const { lang, translate, setLanguage } = useContext(ContentContext)
  
  return (
    <Link to={`/blog/${lang}/${postID}`}>
      <article className="post">
        <div className="card">
          <figure className="card-img-top overlay overlay-1 hover-scale h-25">
            <img src={postImage} alt="" style={{objectFit: 'cover'}} />
            <figcaption>
              <h5 className="from-top mb-0">{translate('blog_read_more')}</h5>
            </figcaption>
          </figure>
          <div className="card-body">
            <div className="post-header">
              <div className="post-category text-line text-primary">
                {category}
              </div>
              <h2 className="post-title mt-1 mb-0">{title}</h2>
            </div>
            <div className="post-content text-dark text-justify">
              <p>{description}</p>
            </div>
          </div>
          <div className="card-footer">
            <ul className="post-meta d-flex mb-0 justify-content-between">
              <li className="post-date">
                <i className="uil uil-calendar-alt"></i>
                <span>{at}</span>
              </li>
              <li className="post-author">
                <i className="uil uil-user"></i>
                <span>{translate('by')} {author}</span>
              </li>
              {/* <li className="post-comments">
                <i className="uil uil-comment"></i>
                {comments_amt}
                <span> Comments</span>
              </li>
              <li className="post-likes ms-auto">
                <a href="#">
                  <i className="uil uil-heart-alt"></i>
                  {likes_amt}
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </article>
    </Link>
  )
}

export default BlogLatestPostCard
