import BlogArticles from '@components/blogArticles'
import BlogHeader from '@components/blogHeader'
import BlogLatestPostCard from '@components/blogLatestPostCard'
import BlogPostCard from '@components/blogPostCard'
import BlogSidebar from '@components/blogSidebar'
import Footer from '@components/footer'
import Header from '@components/header'
import Nav from '@components/nav'
import React, { UIEvent, useEffect, useState, useContext } from 'react'
import { ContentContext } from '../../components/layouts'
import { FaGlobeAfrica } from '@react-icons/all-files/fa/FaGlobeAfrica'

function Blog({
  articlesData,
  popularArticlesData,
  categoriesData,
  previousDatesData
}: {
  articlesData: any
  popularArticlesData: any
  categoriesData: any
  previousDatesData: any
}) {
  const { lang, translate, setLanguage } = useContext(ContentContext)
  const [latestArticleCard, setLatestArticleCard] = useState<JSX.Element>()
  const [activePage, setActivePage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [isCloudEmpty, setIsCloudEmpty] = useState(false)
  const [articles, setArticles] = useState<Array<JSX.Element>>([])

  const populateArticles = async () => {
    try {
      let tempVar: JSX.Element[] = []
      let tempVarLatestArticleCard: JSX.Element
      if (articlesData.length == 1) {
        articlesData.forEach((element: any, x: number) => {
          setLatestArticleCard(prev => (
            <BlogLatestPostCard
              category={element.post_category}
              title={
                lang === 'en'
                  ? element.post_title
                  : lang === 'fr'
                    ? element.localizations.data[0]?.attributes.locale == 'fr' 
                    ? element.localizations.data[0]?.attributes.post_title 
                    : element.localizations.data[1]?.attributes.post_title
                    : lang === 'ar'
                      ? element.localizations.data[1]?.attributes.locale == 'ar' 
                      ? element.localizations.data[1]?.attributes.post_title 
                      : element.localizations.data[0]?.attributes.post_title
                      : element.post_title
              }
              description={
                lang === 'en'
                    ? element.post_description
                    : lang === 'fr'
                      ? element.localizations.data[0]?.attributes.locale == 'fr' 
                      ? element.localizations.data[0]?.attributes.post_description 
                      : element.localizations.data[1]?.attributes.post_description
                      : lang === 'ar'
                        ? element.localizations.data[1]?.attributes.locale == 'ar' 
                        ? element.localizations.data[1]?.attributes.post_description
                        : element.localizations.data[0]?.attributes.post_description
                        : element.post_description
              }
              at={element.post_at}
              author={element.post_author}
              postImage={element.post_image.formats.large.url}
              postID={element.post_id}
              url={element.post_link}
            />
          ))
        })
        setIsCloudEmpty(false)
      } else if (articlesData.length > 1) {
        articlesData.forEach((element: any, x: number) => {
          if (x == articlesData.length - 1) {
            setLatestArticleCard(prev => (
              <BlogLatestPostCard
                category={element.post_category}
                title={
                  lang === 'en'
                    ? element.post_title
                    : lang === 'fr'
                      ? element.localizations.data[0]?.attributes.locale == 'fr' 
                      ? element.localizations.data[0]?.attributes.post_title 
                      : element.localizations.data[1]?.attributes.post_title
                      : lang === 'ar'
                        ? element.localizations.data[1]?.attributes.locale == 'ar' 
                        ? element.localizations.data[1]?.attributes.post_title 
                        : element.localizations.data[0]?.attributes.post_title
                        : element.post_title
                }
                description={
                  lang === 'en'
                    ? element.post_description
                    : lang === 'fr'
                      ? element.localizations.data[0]?.attributes.locale == 'fr' 
                      ? element.localizations.data[0]?.attributes.post_description 
                      : element.localizations.data[1]?.attributes.post_description
                      : lang === 'ar'
                        ? element.localizations.data[1]?.attributes.locale == 'ar' 
                        ? element.localizations.data[1]?.attributes.post_description
                        : element.localizations.data[0]?.attributes.post_description
                        : element.post_description
                }
                at={element.post_at}
                author={element.post_author}
                postImage={element.post_image.formats.large.url}
                postID={element.post_id}
                url={element.post_link}
              />
            ))
          } else {
            tempVar[x] = (
              <BlogPostCard
                category={element.post_category}
                title={
                  lang === 'en'
                    ? element.post_title
                    : lang === 'fr'
                      ? element.localizations.data[0]?.attributes.locale == 'fr' 
                      ? element.localizations.data[0]?.attributes.post_title 
                      : element.localizations.data[1]?.attributes.post_title
                      : lang === 'ar'
                        ? element.localizations.data[1]?.attributes.locale == 'ar' 
                        ? element.localizations.data[1]?.attributes.post_title 
                        : element.localizations.data[0]?.attributes.post_title
                        : element.post_title
                }
                description={
                  lang === 'en'
                    ? element.post_description
                    : lang === 'fr'
                      ? element.localizations.data[0]?.attributes.locale == 'fr' 
                      ? element.localizations.data[0]?.attributes.post_description 
                      : element.localizations.data[1]?.attributes.post_description
                      : lang === 'ar'
                        ? element.localizations.data[1]?.attributes.locale == 'ar' 
                        ? element.localizations.data[1]?.attributes.post_description
                        : element.localizations.data[0]?.attributes.post_description
                        : element.post_description
                }
                at={element.post_at}
                author={element.post_author}
                postImage={element.post_image.formats.large.url}
                postID={element.post_id}
                url={element.post_link}
              />
            )
          }
        })
        setArticles(prev => tempVar)
        setIsCloudEmpty(false)
      } else if (articlesData.length <= 0) {
        setIsCloudEmpty(true)
      }
      setIsLoading(false)
    } catch (err) {
      // console.log('ERROR: ' + err)
    }
  }

  const getPaginatedArr = (arr: JSX.Element[], chunkSize: number) => {
    let res = []
    var x = 0
    while (x < arr.length) {
      if (x === 0) {
        const chunk = arr.slice(x, x + (chunkSize - 2))
        // chunk.unshift(<BlogLatestPostCard/>)
        x = x - 2
        // console.log('FIRST RUN', chunk)
        res.push(chunk)
      } else {
        const chunk = arr.slice(x, x + chunkSize)
        // console.log(x, ' RUN', chunk)
        res.push(chunk)
      }
      x += chunkSize
    }
    return res
  }

  var paginatedArticles = getPaginatedArr(articles, 6)

  useEffect(() => {
    setArticles([])
    setLatestArticleCard(<></>)
    populateArticles()
  }, [lang])

  return (
    <>
      <div className="content-wrapper">
        <Nav />
        <BlogHeader />
        <section className="wrapper bg-white pt-5">
          <div className="container py-10 py-md-10">
            <div className="row gx-lg-8 gx-xl-12">
              <div className="col-lg-8 d-flex flex-column">
                {/* {isCloudEmpty ? (
                  <div className="d-flex align-items-center flex-column col-12 justify-content-center p-10 text-primary">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-article-off"
                        width="100"
                        height="100"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M8 4h11a2 2 0 0 1 2 2v11m-1.172 2.821a1.993 1.993 0 0 1 -.828 .179h-14a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 1.156 -1.814"></path>
                        <path d="M7 8h1m4 0h5"></path>
                        <path d="M7 12h5m4 0h1"></path>
                        <path d="M7 16h9"></path>
                        <path d="M3 3l18 18"></path>
                      </svg>
                    </div>
                    <div>
                      <p className="text-gray-600 dark:text-gray-300">
                        {translate('blog_no_article')}
                      </p>
                    </div>
                  </div>
                ) : null} */}
                <div className="col-12 mb-2">
                  {
                    <div className="p-1">
                      {isLoading == false ? (
                        activePage == 1 ? (
                          <BlogArticles
                            pageNum={activePage}
                            articlesCards={paginatedArticles[activePage - 1]}
                            latestArticleCard={latestArticleCard}
                          />
                        ) : (
                          <BlogArticles
                            articlesCards={paginatedArticles[activePage - 1]}
                            pageNum={activePage}
                          />
                        )
                      ) : (
                        <div className="d-flex justify-content-center p-10 text-primary">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  }
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <nav className="d-flex" aria-label="pagination">
                    <ul className="pagination">
                      <li
                        className={
                          'page-item page-link ' +
                          (activePage == 1 ? 'disabled' : '')
                        }
                        onClick={
                          activePage >= 2
                            ? () => {
                                setActivePage(activePage - 1)
                              }
                            : () => {}
                        }
                      >
                        <span aria-hidden="true">
                          <i
                            className={
                              'uil uil-arrow-left ' +
                              (activePage == 1 ? 'text-secondary' : '')
                            }
                          ></i>
                        </span>
                      </li>

                      {paginatedArticles.map((article, index) => (
                        <li
                          key={index}
                          className={
                            'page-item page-link ' +
                            (activePage === index + 1 ? 'active' : '')
                          }
                          onClick={() => setActivePage(index + 1)}
                        >
                          {index + 1}
                        </li>
                      ))}
                      <a>
                        <li
                          className={
                            'page-item page-link ' +
                            (activePage == paginatedArticles.length
                              ? 'disabled'
                              : '')
                          }
                          onClick={
                            activePage < paginatedArticles.length
                              ? () => {
                                  setActivePage(activePage + 1)
                                }
                              : () => {}
                          }
                        >
                          <span aria-hidden="true">
                            <i
                              className={
                                'uil uil-arrow-right ' +
                                (activePage == paginatedArticles.length
                                  ? 'text-secondary'
                                  : '')
                              }
                            ></i>
                          </span>
                        </li>
                      </a>
                    </ul>
                  </nav>
                </div>
              </div>
              <BlogSidebar
                categoriesData={categoriesData}
                popularArticlesData={popularArticlesData}
                previousDatesData={previousDatesData}
              />
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </>
  )
}

export default Blog
