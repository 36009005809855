import { ContentContext } from '@components/layouts'
import React, { useContext, useEffect } from 'react'
import { FaGlobeAfrica } from '@react-icons/all-files/fa/FaGlobeAfrica'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast, { Toaster } from 'react-hot-toast'

const BlogHeader = () => {
  const { lang, translate, setLanguage } = useContext(ContentContext)

  return (
    <section className="section-frame overflow-hidden">
      <div className="wrapper"
      style={{
        backgroundImage: "url('/bg22.png')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
        backgroundSize: '100%',
      }}
      >
        <div className="container py-12 py-md-16 text-center">
          <div className="row">
            <div className="col-md-7 col-lg-6 col-xl-5 mx-auto">
              <h1 className="display-1 mb-3">
                {translate('blog_header_title')}
              </h1>
              <p className="lead px-lg-5 px-xxl-8 mb-1">
                {translate('blog_header_description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogHeader
