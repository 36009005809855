import React, { UIEvent, useEffect, useState, useContext } from 'react'
import Layout from '../components/layouts'
import { graphql, Script, ScriptStrategy } from 'gatsby'
import Blog from '@components/blogPage'

export const pageQuery = graphql`
  query NanodevBlogArticlesQuery {
    everyPost: allStrapiNanodevBlog {
      nodes {
        post_at
        post_category
        post_author
        post_description
        post_id
        post_link
        post_title
        id
        post_image {
          formats {
            large {
              url
            }
          }
        }
        localizations {
          data {
            attributes {
              locale
              post_description
              post_title
            }
            id
          }
        }
      }
    }
    popularPosts: allStrapiNanodevBlog(filter: { post_likes_amt: { gte: 5 } }) {
      nodes {
        post_title
        post_at
        post_id
        post_link
        post_image {
          formats {
            small {
              url
            }
          }
        }
        localizations {
          data {
            attributes {
              locale
              post_title
            }
            id
          }
        }
      }
    }
    categories: allStrapiNanodevBlog {
      nodes {
        post_category
      }
    }
    previousDates: allStrapiNanodevBlog {
      nodes {
        post_at
      }
    }
  }
`

const BlogPage = ({ data }: { data: any }) => {
  const isBrowser = typeof window !== 'undefined' ? true : false
  isBrowser ? document.body.setAttribute('data-bs-spy', 'scroll') : null
  const [pluginsLoaded, setPluginsLoaded] = useState(false)
  const [jqueryLoaded, setJQueryLoaded] = useState(false)
  const [svgInjectLoaded, setSvgInjectLoaded] = useState(false)

console.log("CONTENT", data.everyPost.nodes)

  return (
    <Layout>
      <Blog
        articlesData={data.everyPost.nodes}
        categoriesData={data.categories.nodes}
        popularArticlesData={data.popularPosts.nodes}
        previousDatesData={data.previousDates.nodes}
      />
      {isBrowser && (
        <Script
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
          integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ=="
          crossOrigin="anonymous"
          onLoad={() => setJQueryLoaded(true)}
        />
      )}

      {jqueryLoaded && isBrowser && (
        <Script
          src="/plugins.js"
          key={'pluginjs'}
          strategy={ScriptStrategy.postHydrate}
          onLoad={() => setPluginsLoaded(true)}
        />
      )}

      {jqueryLoaded && isBrowser && (
        <Script
          src="/svg-inject.min.js"
          key={'svg-inject'}
          onLoad={() => setSvgInjectLoaded(true)}
        />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/headhesive.min.js" key={'headhesive'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/scrollCue.min.js" key={'scrollCue'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/glightbox.min.js" key={'glightbox'} />
      )}
      {jqueryLoaded && isBrowser && (
        <Script src="/owl.carousel.min.js" key={'owl.carousel'} />
      )}
      {svgInjectLoaded && isBrowser && <Script src="/theme.js" key={'theme'} />}
    </Layout>
  )
}

export default BlogPage

export const Head = () => {
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="title" property="og:title" content="nano dev inc" />
      <meta name="type" property="og:type" content="website" />
      <meta
        name="image"
        property="og:image"
        content="https://ashy-sand-0c4dc6a10.2.azurestaticapps.net/nanologo.png"
      />
      <meta name="url" property="og:url" content="https://www.nanodev.ca" />
      <meta
        name="description"
        property="og:description"
        content="WE PROVIDE CREATIVE AND INNOVATIVE SOLUTIONS"
      />
      <meta property="og:site_name" content="nano dev inc"></meta>

      <meta
        name="keywords"
        content="IT, Software, business, corporate, creative, marketing, AI, modern, startup, blog, infos, newsletter_mail"
      />
      <meta name="author" content="NanoDev" />
      <title>NanoDev | Blog</title>
    </>
  )
}
